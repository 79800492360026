import React from "react";
import * as Yup from "yup";

import {FormikForm} from "@pg-mono/formik-utils";

import {validationMessages} from "../../../form/utils/validation_messages";
import {FavouritesApplicationFormHandler, IFavouritesApplicationFormHandlerProps} from "./FavouritesApplicationFormHandler";
import {FavouritesApplicationForm} from "./FavourtiesApplicationForm";
import {getInitialFavouritesListApplicationFormValues} from "./utils";

type IProps = IFavouritesApplicationFormHandlerProps;

export const FavouritesApplication = (props: IProps) => {
    const validationSchema = Yup.object({
        email: Yup.string().required(validationMessages.required).email(validationMessages.email),
        name: Yup.string().required(validationMessages.required)
    });

    return (
        <div>
            <FormikForm
                initialValues={getInitialFavouritesListApplicationFormValues()}
                onSubmit={(_, {setSubmitting}) => {
                    setSubmitting(true);
                }}
                validationSchema={validationSchema}
                validateOnMount
            >
                <FavouritesApplicationForm />
                <FavouritesApplicationFormHandler {...props} />
            </FormikForm>
        </div>
    );
};
import React from "react";
import * as Yup from "yup";

import {FormikForm} from "@pg-mono/formik-utils";

import {validationMessages} from "../../../form/utils/validation_messages";
import {FavouritesApplicationFormHandler, IFavouritesApplicationFormHandlerProps} from "./FavouritesApplicationFormHandler";
import {FavouritesApplicationForm} from "./FavourtiesApplicationForm";
import {getInitialFavouritesListApplicationFormValues} from "./utils";

type IProps = IFavouritesApplicationFormHandlerProps;

export const FavouritesApplication = (props: IProps) => {
    const validationSchema = Yup.object({
        email: Yup.string().required(validationMessages.required).email(validationMessages.email),
        name: Yup.string().required(validationMessages.required)
    });

    return (
        <div>
            <FormikForm
                initialValues={getInitialFavouritesListApplicationFormValues()}
                onSubmit={(_, {setSubmitting}) => {
                    setSubmitting(true);
                }}
                validationSchema={validationSchema}
                validateOnMount
            >
                <FavouritesApplicationForm />
                <FavouritesApplicationFormHandler {...props} />
            </FormikForm>
        </div>
    );
};
