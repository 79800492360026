import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {useFavourites} from "../../favourite/hooks/use_favourites";
import {fetchMyOffersFavouritesList, removeFromMyOffersFavouritesList} from "../actions/fetch_my_offers_favourites_list";

export const useMyOffersFavourites = (fetch = false) => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const favouriteProperties = useSelector((store: IRPStore) => store.myOffers.favourites.properties.list);
    const favouritePropertiesRequest = useSelector((store: IRPStore) => store.myOffers.favourites.properties.fetchRequest);
    const favouriteOffers = useSelector((store: IRPStore) => store.myOffers.favourites.offers.list);
    const favouriteOffersRequest = useSelector((store: IRPStore) => store.myOffers.favourites.offers.fetchRequest);

    const favourites = useFavourites();

    const favouritePropertiesAmount = favourites.favouritePropertiesIds.length;
    const favouriteOffersAmount = favourites.favouriteOfferIds.length;
    const favouritesAvailable = favouritePropertiesAmount > 0 || favouriteOffersAmount > 0;

    const authenticatedRef = useRef(isAuthenticated);

    const propertiesFetchFinished = [RequestState.Success, RequestState.Error].includes(favouritePropertiesRequest);
    const offersFetchFinished = [RequestState.Success, RequestState.Error].includes(favouriteOffersRequest);
    const detailsFetchFinished = propertiesFetchFinished && offersFetchFinished;

    useEffect(() => {
        //  Load my offers favourite details after auth changed
        const authChanged = authenticatedRef.current !== isAuthenticated;

        if (authChanged) {
            dispatch(fetchMyOffersFavouritesList());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (fetch && favourites.fetchRequest === RequestState.Success) {
            // Favourite data has been loaded into the store - we can download offers and properties
            dispatch(fetchMyOffersFavouritesList());
        }
    }, [fetch, favourites.fetchRequest]);

    const userFavouritesLoaded = favourites.loaded && favouritesAvailable && detailsFetchFinished;
    const noFavouritesToLoad = favourites.loaded && !favouritesAvailable;

    return {
        loaded: userFavouritesLoaded || noFavouritesToLoad,
        favouriteOffers,
        favouriteProperties,
        removeFavouriteProperty: async (propertyId: number) => {
            await favourites.removePropertyFromFavourites(propertyId);
            dispatch(removeFromMyOffersFavouritesList({offers: [], properties: [propertyId]}));
        },
        removeFavouriteOffer: async (offerId: number) => {
            await favourites.removeOfferFromFavourites(offerId);
            dispatch(removeFromMyOffersFavouritesList({offers: [offerId], properties: []}));
        }
    };
};
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {useFavourites} from "../../favourite/hooks/use_favourites";
import {fetchMyOffersFavouritesList, removeFromMyOffersFavouritesList} from "../actions/fetch_my_offers_favourites_list";

export const useMyOffersFavourites = (fetch = false) => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const favouriteProperties = useSelector((store: IRPStore) => store.myOffers.favourites.properties.list);
    const favouritePropertiesRequest = useSelector((store: IRPStore) => store.myOffers.favourites.properties.fetchRequest);
    const favouriteOffers = useSelector((store: IRPStore) => store.myOffers.favourites.offers.list);
    const favouriteOffersRequest = useSelector((store: IRPStore) => store.myOffers.favourites.offers.fetchRequest);

    const favourites = useFavourites();

    const favouritePropertiesAmount = favourites.favouritePropertiesIds.length;
    const favouriteOffersAmount = favourites.favouriteOfferIds.length;
    const favouritesAvailable = favouritePropertiesAmount > 0 || favouriteOffersAmount > 0;

    const authenticatedRef = useRef(isAuthenticated);

    const propertiesFetchFinished = [RequestState.Success, RequestState.Error].includes(favouritePropertiesRequest);
    const offersFetchFinished = [RequestState.Success, RequestState.Error].includes(favouriteOffersRequest);
    const detailsFetchFinished = propertiesFetchFinished && offersFetchFinished;

    useEffect(() => {
        //  Load my offers favourite details after auth changed
        const authChanged = authenticatedRef.current !== isAuthenticated;

        if (authChanged) {
            dispatch(fetchMyOffersFavouritesList());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (fetch && favourites.fetchRequest === RequestState.Success) {
            // Favourite data has been loaded into the store - we can download offers and properties
            dispatch(fetchMyOffersFavouritesList());
        }
    }, [fetch, favourites.fetchRequest]);

    const userFavouritesLoaded = favourites.loaded && favouritesAvailable && detailsFetchFinished;
    const noFavouritesToLoad = favourites.loaded && !favouritesAvailable;

    return {
        loaded: userFavouritesLoaded || noFavouritesToLoad,
        favouriteOffers,
        favouriteProperties,
        removeFavouriteProperty: async (propertyId: number) => {
            await favourites.removePropertyFromFavourites(propertyId);
            dispatch(removeFromMyOffersFavouritesList({offers: [], properties: [propertyId]}));
        },
        removeFavouriteOffer: async (offerId: number) => {
            await favourites.removeOfferFromFavourites(offerId);
            dispatch(removeFromMyOffersFavouritesList({offers: [offerId], properties: []}));
        }
    };
};
