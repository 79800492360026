/*
    This function purpose is to check whether a vendor can be considered as target for sending a lead/application

    Currently, excluded vendors are:
    - 7478 - Echo investment - this vendor has its own api and application form
 */
export const isVendorExcluded = (vendorId: number): boolean => [7478].includes(vendorId);
/*
    This function purpose is to check whether a vendor can be considered as target for sending a lead/application

    Currently, excluded vendors are:
    - 7478 - Echo investment - this vendor has its own api and application form
 */
export const isVendorExcluded = (vendorId: number): boolean => [7478].includes(vendorId);
