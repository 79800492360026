import React from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize, m} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {getOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {IFavouritesConfirmationList} from "./favourites_confirmation_list.types";
import {FavouritesConfirmationListItem} from "./FavouritesConfirmationListItem";

interface IProps {
    confirmationList: IFavouritesConfirmationList;
    className?: string;
}

export const FavouritesConfirmationList = (props: IProps) => {
    const {
        confirmationList: {sentOffers, rejectedOffers, sentProperties, rejectedProperties},
        className
    } = props;

    return (
        <div css={confirmationList} className={className}>
            <Text variant="headline_6" css={title}>
                Wysłano {sentProperties.length + sentOffers.length} na{" "}
                {sentProperties.length + sentOffers.length + rejectedProperties.length + rejectedOffers.length} zapytań.
            </Text>

            <div css={listWrap}>
                <ul css={list}>
                    {sentProperties.length > 0 &&
                        sentProperties.map((property) => (
                            <FavouritesConfirmationListItem
                                key={`sp${property.id}`}
                                error={false}
                                image={property.offer.main_image.m_img_155x87}
                                name={property.offer.name}
                                address={property.offer.address}
                                vendorId={property.offer.vendor.id}
                                isActive={property.offer.is_active}
                                displayType={property.offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(property.offer)}
                                phoneNumber={property.offer.configuration.form_placeholder_phone}
                                offerType={property.offer.type}
                                area={property.area}
                                roomsCount={property.rooms}
                                type="property"
                            />
                        ))}

                    {sentOffers.length > 0 &&
                        sentOffers.map((offer) => (
                            <FavouritesConfirmationListItem
                                key={`so${offer.id}`}
                                error={false}
                                image={offer.main_image.m_img_155x87}
                                name={offer.name}
                                address={offer.address}
                                vendorId={offer.vendor.id}
                                isActive={offer.is_active}
                                displayType={offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(offer)}
                                phoneNumber={offer.configuration.form_placeholder_phone}
                                offerType={offer.type}
                                type="offer"
                            />
                        ))}

                    {rejectedProperties.length > 0 &&
                        rejectedProperties.map((property) => (
                            <FavouritesConfirmationListItem
                                key={`rp${property.id}`}
                                error={false}
                                image={property.offer.main_image.m_img_155x87}
                                name={property.offer.name}
                                address={property.offer.address}
                                vendorId={property.offer.vendor.id}
                                isActive={property.offer.is_active}
                                displayType={property.offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(property.offer)}
                                phoneNumber={property.offer.configuration.form_placeholder_phone}
                                offerType={property.offer.type}
                                area={property.area}
                                roomsCount={property.rooms}
                                type="property"
                            />
                        ))}

                    {rejectedOffers.length > 0 &&
                        rejectedOffers.map((offer) => (
                            <FavouritesConfirmationListItem
                                key={`ro${offer.id}`}
                                error
                                image={offer.main_image.m_img_155x87}
                                name={offer.name}
                                address={offer.address}
                                vendorId={offer.vendor.id}
                                isActive={offer.is_active}
                                displayType={offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(offer)}
                                phoneNumber={offer.configuration.form_placeholder_phone}
                                offerType={offer.type}
                                type="offer"
                            />
                        ))}
                </ul>
            </div>
        </div>
    );
};

/*
    Styles
 */
const confirmationList = css`
    display: flex;
    flex-direction: column;
`;

const listWrap = css`
    max-height: 320px;
    max-width: 600px;
    overflow-y: scroll;
`;

const title = (theme: Theme) => css`
    ${m(0, 0, 4, 0)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${m(0, 0, 5, 0)};
    }
`;

const list = css`
    padding: 0;

    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(1)};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize, m} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {getOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {IFavouritesConfirmationList} from "./favourites_confirmation_list.types";
import {FavouritesConfirmationListItem} from "./FavouritesConfirmationListItem";

interface IProps {
    confirmationList: IFavouritesConfirmationList;
    className?: string;
}

export const FavouritesConfirmationList = (props: IProps) => {
    const {
        confirmationList: {sentOffers, rejectedOffers, sentProperties, rejectedProperties},
        className
    } = props;

    return (
        <div css={confirmationList} className={className}>
            <Text variant="headline_6" css={title}>
                Wysłano {sentProperties.length + sentOffers.length} na{" "}
                {sentProperties.length + sentOffers.length + rejectedProperties.length + rejectedOffers.length} zapytań.
            </Text>

            <div css={listWrap}>
                <ul css={list}>
                    {sentProperties.length > 0 &&
                        sentProperties.map((property) => (
                            <FavouritesConfirmationListItem
                                key={`sp${property.id}`}
                                error={false}
                                image={property.offer.main_image.m_img_155x87}
                                name={property.offer.name}
                                address={property.offer.address}
                                vendorId={property.offer.vendor.id}
                                isActive={property.offer.is_active}
                                displayType={property.offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(property.offer)}
                                phoneNumber={property.offer.configuration.form_placeholder_phone}
                                offerType={property.offer.type}
                                area={property.area}
                                roomsCount={property.rooms}
                                type="property"
                            />
                        ))}

                    {sentOffers.length > 0 &&
                        sentOffers.map((offer) => (
                            <FavouritesConfirmationListItem
                                key={`so${offer.id}`}
                                error={false}
                                image={offer.main_image.m_img_155x87}
                                name={offer.name}
                                address={offer.address}
                                vendorId={offer.vendor.id}
                                isActive={offer.is_active}
                                displayType={offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(offer)}
                                phoneNumber={offer.configuration.form_placeholder_phone}
                                offerType={offer.type}
                                type="offer"
                            />
                        ))}

                    {rejectedProperties.length > 0 &&
                        rejectedProperties.map((property) => (
                            <FavouritesConfirmationListItem
                                key={`rp${property.id}`}
                                error={false}
                                image={property.offer.main_image.m_img_155x87}
                                name={property.offer.name}
                                address={property.offer.address}
                                vendorId={property.offer.vendor.id}
                                isActive={property.offer.is_active}
                                displayType={property.offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(property.offer)}
                                phoneNumber={property.offer.configuration.form_placeholder_phone}
                                offerType={property.offer.type}
                                area={property.area}
                                roomsCount={property.rooms}
                                type="property"
                            />
                        ))}

                    {rejectedOffers.length > 0 &&
                        rejectedOffers.map((offer) => (
                            <FavouritesConfirmationListItem
                                key={`ro${offer.id}`}
                                error
                                image={offer.main_image.m_img_155x87}
                                name={offer.name}
                                address={offer.address}
                                vendorId={offer.vendor.id}
                                isActive={offer.is_active}
                                displayType={offer.configuration.display_type}
                                displayStatus={getOfferDisplayStatus(offer)}
                                phoneNumber={offer.configuration.form_placeholder_phone}
                                offerType={offer.type}
                                type="offer"
                            />
                        ))}
                </ul>
            </div>
        </div>
    );
};

/*
    Styles
 */
const confirmationList = css`
    display: flex;
    flex-direction: column;
`;

const listWrap = css`
    max-height: 320px;
    max-width: 600px;
    overflow-y: scroll;
`;

const title = (theme: Theme) => css`
    ${m(0, 0, 4, 0)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${m(0, 0, 5, 0)};
    }
`;

const list = css`
    padding: 0;

    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(1)};
`;
