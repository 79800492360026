/**
 * Based on previous ApplicationForm in Favourites list
 * TODO: Should be analysed and rewritten after "global state management decision"
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useEffect, useRef} from "react";
import {connect, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {bindActionCreators, Dispatch} from "redux";

import {omit, pick} from "@pg-mono/nodash";
import {IAppError} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IApplicationData} from "../../../application/reducers/application_reducer";
import {IApplication} from "../../../application/types/IApplication";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {authUserAfterApplicationSent} from "../../../auth/actions/auth_user_after_application_sent";
import {fromJsonValues} from "../../../form/translate/from_json_values";
import {toJsonValues} from "../../../form/translate/to_json_values";
import {getPhoneString} from "../../../form/utils/get_phone_string";
import {gtmInquiryOnApplicationSuccess} from "../../../lead/tracking/gtm_inquiry_on_application_success";
import {getSentLeadFormValuesFromStorage, setSentLeadFormValuesInStorage} from "../../../lead/utils/sent_lead_form_values";
import {useMyOffersFavourites} from "../../../my_offers/hooks/use_my_offers_favourites";
import {OfferDisplayType} from "../../../offer/helpers/OfferDisplayType";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IOfferDetail} from "../../../offer/types/IOfferDetail";
import {IPropertyDetail} from "../../../property/types/IPropertyDetail";
import {IPropertyDetailOffer} from "../../../property/types/IPropertyDetailOffer";
import {BoundAction} from "../../../store/utils/bound_action";
import {hitApplicationFavouriteOffersError, hitApplicationFavouritesSuccess} from "../../../tracking/algolytics_hits/application_hit";
import {fetchUserProfile, forceFetchUserProfile} from "../../../user/actions/fetch_user_profile";
import {patchUserProfileFromApplication} from "../../../user/actions/patch_user_profile_from_application";
import {ViewType} from "../../../view_type/ViewType";
import {postFavouritesApplication} from "../../actions/post_favourites_application";
import {isVendorExcluded} from "../../utils/is_vendor_excluded";
import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";
import {favouritesListApplicationFormFields} from "./utils";

type IProps = IStateProps & IActionsProps & IFavouritesApplicationFormHandlerProps;

export interface IFavouritesApplicationFormHandlerProps {
    storeHash: string;
    onSubmitStart?: () => void;
    onSubmitSuccess?: (
        applicationData: IFavouriteData[],
        sentOffers: IOfferDetail[],
        rejectedOffers: IOfferDetail[],
        sentProperties: IPropertyDetail[],
        rejectedProperties: IPropertyDetail[]
    ) => void;
    onSubmitFinish?: () => void;
}

interface IActionsProps {
    postFavouritesApplication: BoundAction<typeof postFavouritesApplication>;
    fetchUserProfile: BoundAction<typeof fetchUserProfile>;
    forceFetchUserProfile: BoundAction<typeof forceFetchUserProfile>;
    gtmInquiryOnApplicationSuccess: typeof gtmInquiryOnApplicationSuccess;
    addAppliedOffers: typeof addAppliedOffers;
    authUserAfterApplicationSent: BoundAction<typeof authUserAfterApplicationSent>;
    patchUserProfileFromApplication: BoundAction<typeof patchUserProfileFromApplication>;
}

interface IStateProps {}

export const FavouritesApplicationFormHandlerC = (props: IProps) => {
    const {storeHash, onSubmitStart, onSubmitFinish, onSubmitSuccess} = props;

    const {favouriteOffers, favouriteProperties} = useMyOffersFavourites();
    const {values, setValues, isSubmitting, setSubmitting, setErrors} = useFormikContext<IFavouritesListApplicationFormValues>();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);

    const userProfileDataUpdated = useRef<boolean>(false);

    const sendFavouritesApplication = (favouriteData: IFavouriteData) => {
        const source = favouriteData.type === "offer" ? ApplicationSource.FavouritesOfferInquiry : ApplicationSource.FavouritesPropertyInquiry;

        const applicationData: Partial<IApplication> = {
            source,
            source_section: ApplicationSourceSection.FAVOURITES,
            text: "Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji. Proszę o kontakt.",
            [favouriteData.type === "offer" ? "offer" : "property"]: favouriteData.id,
            ...toJsonValues(favouritesListApplicationFormFields, values)
        };

        return props.postFavouritesApplication(applicationData, storeHash, {
            on400Response: (error) => {
                hitApplicationFavouriteOffersError(source, ApplicationSourceSection.FAVOURITES, {
                    appError: error.appError as IAppError,
                    extraPayloadViewType: ViewType.MY_FAVOURITES_LIST
                });

                if (error.appError) {
                    setErrors(error.appError.fieldErrors);
                }
            }
        });
    };

    useEffect(() => {
        (async function updateInitialFormValues() {
            //  TODO: Consider doing this before rendering the form
            const storageUserData = getSentLeadFormValuesFromStorage();

            if (isAuthenticated) {
                const userProfileData = await props.fetchUserProfile();

                if (userProfileData) {
                    const formCheckboxesToBeRestored = {
                        ...pick(storageUserData, ["financing_services"]),
                        new_property_notification_consent: userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent
                    };

                    const profileDataFormValues = fromJsonValues(favouritesListApplicationFormFields, {
                        ...values,
                        ...userProfileData,
                        ...formCheckboxesToBeRestored
                    });

                    setValues(profileDataFormValues as IFavouritesListApplicationFormValues);
                }
            }

            if (!isAuthenticated && storageUserData) {
                const formFieldsToRestore = ["email", "name", "phone", "financing_services"] as (keyof typeof storageUserData)[];
                const restoredFormData = pick(storageUserData, formFieldsToRestore);

                const storageFormValues = fromJsonValues(favouritesListApplicationFormFields, {
                    ...values,
                    ...restoredFormData
                });

                setValues(storageFormValues as IFavouritesListApplicationFormValues);
            }
        })();
    }, []);

    useEffect(() => {
        (async function handleSubmit() {
            if (isSubmitting) {
                if (onSubmitStart) {
                    onSubmitStart();
                }

                const applicationProperties = getApplicationProperties(favouriteProperties);
                const applicationOffers = getApplicationOffers(favouriteOffers);
                const applicationData: IFavouriteData[] = [...applicationProperties.data, ...applicationOffers.data];

                //  Finally send application data
                try {
                    await asyncForEach(applicationData, async (element: IFavouriteData) => {
                        const application = await sendFavouritesApplication(element);

                        if (!application) {
                            await Promise.reject();
                        } else {
                            // user was created when submitting the application - try to log in
                            if (application.user_created) {
                                await props.authUserAfterApplicationSent(application);
                            }

                            // try to update logged-in user data
                            if (isAuthenticated && userProfileData && !application.user_created && !userProfileDataUpdated.current) {
                                await props.patchUserProfileFromApplication(userProfileData, application);

                                userProfileDataUpdated.current = true;
                            }

                            isAuthenticated && addAppliedOffers([application.offer]);

                            hitApplicationFavouritesSuccess(application, application.source_section, {extraPayloadViewType: ViewType.MY_FAVOURITES_LIST});

                            if (element.type === "offer") {
                                const currentOffer = favouriteOffers.filter((offer) => offer.id === element.id)[0];
                                const data = {
                                    offer: {detail: currentOffer},
                                    vendor: {detail: currentOffer.vendor}
                                } as unknown as IApplicationData;

                                gtmInquiryOnApplicationSuccess(application, data, ViewType.MY_FAVOURITES_LIST, "standard");
                            } else {
                                const currentProperty = favouriteProperties.filter((property) => property.id === element.id)[0];

                                const data = {
                                    offer: {detail: currentProperty.offer},
                                    vendor: {detail: currentProperty.offer.vendor},
                                    property: {detail: omit(currentProperty, ["offer"])}
                                } as unknown as IApplicationData;

                                gtmInquiryOnApplicationSuccess(application, data, ViewType.MY_FAVOURITES_LIST, "standard");
                            }

                            await Promise.resolve();
                        }
                    });

                    //  After submit success
                    const userStorageDataToSave = pick(values, ["email", "name", "phone", "financing_services", "new_property_notification_consent"]);

                    setSentLeadFormValuesInStorage({
                        ...userStorageDataToSave,
                        phone: getPhoneString(userStorageDataToSave.phone)
                    });

                    if (onSubmitSuccess) {
                        onSubmitSuccess(
                            applicationData,
                            applicationOffers.sent,
                            applicationOffers.rejected,
                            applicationProperties.sent,
                            applicationProperties.rejected
                        );
                    }
                } catch (e: unknown) {
                    //  After submit failure
                    console.log("Error", e);
                }

                //  After success or failure
                if (onSubmitFinish) {
                    onSubmitFinish();
                }

                setSubmitting(false);
            }
        })();
    }, [isSubmitting]);

    return null;
};

export const FavouritesApplicationFormHandler = connect<IStateProps, IActionsProps, IFavouritesApplicationFormHandlerProps, IRPStore>(
    () => ({}),
    mapDispatchToProps
)(FavouritesApplicationFormHandlerC);

function mapDispatchToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                postFavouritesApplication,
                addAppliedOffers,
                fetchUserProfile,
                forceFetchUserProfile,
                gtmInquiryOnApplicationSuccess,
                authUserAfterApplicationSent,
                patchUserProfileFromApplication
            },
            dispatch
        )
    };
}

//  Utils
//Because of user creation and cookies each application should be send separately
async function asyncForEach(array: IFavouriteData[], callback: (element: IFavouriteData, index: number, array: IFavouriteData[]) => void) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

/*
    Below functions provide filtered data for api requests and return information about filtering
*/
interface IApplicationItems<T> {
    data: IFavouriteData[];
    sent: T[];
    rejected: T[];
}

interface IFavouriteData {
    id: number;
    type: string;
}

const getApplicationProperties = (selectedProperties: IPropertyDetail[]) =>
    selectedProperties.reduce<IApplicationItems<IPropertyDetail>>(
        (acc, property) => {
            if (isOfferRejected(property.offer)) {
                return {
                    ...acc,
                    rejected: [...acc.rejected, property]
                };
            }

            return {
                ...acc,
                sent: [...acc.sent, property],
                data: [...acc.data, {type: "property", id: property.id}]
            };
        },
        {sent: [], rejected: [], data: []}
    );

const getApplicationOffers = (selectedOffers: IOfferDetail[]) =>
    selectedOffers.reduce<IApplicationItems<IOfferDetail>>(
        (acc, offer) => {
            if (isOfferRejected(offer)) {
                return {
                    ...acc,
                    rejected: [...acc.rejected, offer]
                };
            }

            return {
                ...acc,
                sent: [...acc.sent, offer],
                data: [...acc.data, {type: "offer", id: offer.id}]
            };
        },
        {sent: [], rejected: [], data: []}
    );

const isOfferRejected = (offer: IPropertyDetailOffer | IOfferDetail) =>
    isVendorExcluded(offer.vendor.id) || !offer.is_active || offer.configuration.display_type !== OfferDisplayType.FOR_SALE;
/**
 * Based on previous ApplicationForm in Favourites list
 * TODO: Should be analysed and rewritten after "global state management decision"
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useEffect, useRef} from "react";
import {connect, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {bindActionCreators, Dispatch} from "redux";

import {omit, pick} from "@pg-mono/nodash";
import {IAppError} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IApplicationData} from "../../../application/reducers/application_reducer";
import {IApplication} from "../../../application/types/IApplication";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {authUserAfterApplicationSent} from "../../../auth/actions/auth_user_after_application_sent";
import {fromJsonValues} from "../../../form/translate/from_json_values";
import {toJsonValues} from "../../../form/translate/to_json_values";
import {getPhoneString} from "../../../form/utils/get_phone_string";
import {gtmInquiryOnApplicationSuccess} from "../../../lead/tracking/gtm_inquiry_on_application_success";
import {getSentLeadFormValuesFromStorage, setSentLeadFormValuesInStorage} from "../../../lead/utils/sent_lead_form_values";
import {useMyOffersFavourites} from "../../../my_offers/hooks/use_my_offers_favourites";
import {OfferDisplayType} from "../../../offer/helpers/OfferDisplayType";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IOfferDetail} from "../../../offer/types/IOfferDetail";
import {IPropertyDetail} from "../../../property/types/IPropertyDetail";
import {IPropertyDetailOffer} from "../../../property/types/IPropertyDetailOffer";
import {BoundAction} from "../../../store/utils/bound_action";
import {hitApplicationFavouriteOffersError, hitApplicationFavouritesSuccess} from "../../../tracking/algolytics_hits/application_hit";
import {fetchUserProfile, forceFetchUserProfile} from "../../../user/actions/fetch_user_profile";
import {patchUserProfileFromApplication} from "../../../user/actions/patch_user_profile_from_application";
import {ViewType} from "../../../view_type/ViewType";
import {postFavouritesApplication} from "../../actions/post_favourites_application";
import {isVendorExcluded} from "../../utils/is_vendor_excluded";
import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";
import {favouritesListApplicationFormFields} from "./utils";

type IProps = IStateProps & IActionsProps & IFavouritesApplicationFormHandlerProps;

export interface IFavouritesApplicationFormHandlerProps {
    storeHash: string;
    onSubmitStart?: () => void;
    onSubmitSuccess?: (
        applicationData: IFavouriteData[],
        sentOffers: IOfferDetail[],
        rejectedOffers: IOfferDetail[],
        sentProperties: IPropertyDetail[],
        rejectedProperties: IPropertyDetail[]
    ) => void;
    onSubmitFinish?: () => void;
}

interface IActionsProps {
    postFavouritesApplication: BoundAction<typeof postFavouritesApplication>;
    fetchUserProfile: BoundAction<typeof fetchUserProfile>;
    forceFetchUserProfile: BoundAction<typeof forceFetchUserProfile>;
    gtmInquiryOnApplicationSuccess: typeof gtmInquiryOnApplicationSuccess;
    addAppliedOffers: typeof addAppliedOffers;
    authUserAfterApplicationSent: BoundAction<typeof authUserAfterApplicationSent>;
    patchUserProfileFromApplication: BoundAction<typeof patchUserProfileFromApplication>;
}

interface IStateProps {}

export const FavouritesApplicationFormHandlerC = (props: IProps) => {
    const {storeHash, onSubmitStart, onSubmitFinish, onSubmitSuccess} = props;

    const {favouriteOffers, favouriteProperties} = useMyOffersFavourites();
    const {values, setValues, isSubmitting, setSubmitting, setErrors} = useFormikContext<IFavouritesListApplicationFormValues>();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);

    const userProfileDataUpdated = useRef<boolean>(false);

    const sendFavouritesApplication = (favouriteData: IFavouriteData) => {
        const source = favouriteData.type === "offer" ? ApplicationSource.FavouritesOfferInquiry : ApplicationSource.FavouritesPropertyInquiry;

        const applicationData: Partial<IApplication> = {
            source,
            source_section: ApplicationSourceSection.FAVOURITES,
            text: "Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji. Proszę o kontakt.",
            [favouriteData.type === "offer" ? "offer" : "property"]: favouriteData.id,
            ...toJsonValues(favouritesListApplicationFormFields, values)
        };

        return props.postFavouritesApplication(applicationData, storeHash, {
            on400Response: (error) => {
                hitApplicationFavouriteOffersError(source, ApplicationSourceSection.FAVOURITES, {
                    appError: error.appError as IAppError,
                    extraPayloadViewType: ViewType.MY_FAVOURITES_LIST
                });

                if (error.appError) {
                    setErrors(error.appError.fieldErrors);
                }
            }
        });
    };

    useEffect(() => {
        (async function updateInitialFormValues() {
            //  TODO: Consider doing this before rendering the form
            const storageUserData = getSentLeadFormValuesFromStorage();

            if (isAuthenticated) {
                const userProfileData = await props.fetchUserProfile();

                if (userProfileData) {
                    const formCheckboxesToBeRestored = {
                        ...pick(storageUserData, ["financing_services"]),
                        new_property_notification_consent: userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent
                    };

                    const profileDataFormValues = fromJsonValues(favouritesListApplicationFormFields, {
                        ...values,
                        ...userProfileData,
                        ...formCheckboxesToBeRestored
                    });

                    setValues(profileDataFormValues as IFavouritesListApplicationFormValues);
                }
            }

            if (!isAuthenticated && storageUserData) {
                const formFieldsToRestore = ["email", "name", "phone", "financing_services"] as (keyof typeof storageUserData)[];
                const restoredFormData = pick(storageUserData, formFieldsToRestore);

                const storageFormValues = fromJsonValues(favouritesListApplicationFormFields, {
                    ...values,
                    ...restoredFormData
                });

                setValues(storageFormValues as IFavouritesListApplicationFormValues);
            }
        })();
    }, []);

    useEffect(() => {
        (async function handleSubmit() {
            if (isSubmitting) {
                if (onSubmitStart) {
                    onSubmitStart();
                }

                const applicationProperties = getApplicationProperties(favouriteProperties);
                const applicationOffers = getApplicationOffers(favouriteOffers);
                const applicationData: IFavouriteData[] = [...applicationProperties.data, ...applicationOffers.data];

                //  Finally send application data
                try {
                    await asyncForEach(applicationData, async (element: IFavouriteData) => {
                        const application = await sendFavouritesApplication(element);

                        if (!application) {
                            await Promise.reject();
                        } else {
                            // user was created when submitting the application - try to log in
                            if (application.user_created) {
                                await props.authUserAfterApplicationSent(application);
                            }

                            // try to update logged-in user data
                            if (isAuthenticated && userProfileData && !application.user_created && !userProfileDataUpdated.current) {
                                await props.patchUserProfileFromApplication(userProfileData, application);

                                userProfileDataUpdated.current = true;
                            }

                            isAuthenticated && addAppliedOffers([application.offer]);

                            hitApplicationFavouritesSuccess(application, application.source_section, {extraPayloadViewType: ViewType.MY_FAVOURITES_LIST});

                            if (element.type === "offer") {
                                const currentOffer = favouriteOffers.filter((offer) => offer.id === element.id)[0];
                                const data = {
                                    offer: {detail: currentOffer},
                                    vendor: {detail: currentOffer.vendor}
                                } as unknown as IApplicationData;

                                gtmInquiryOnApplicationSuccess(application, data, ViewType.MY_FAVOURITES_LIST, "standard");
                            } else {
                                const currentProperty = favouriteProperties.filter((property) => property.id === element.id)[0];

                                const data = {
                                    offer: {detail: currentProperty.offer},
                                    vendor: {detail: currentProperty.offer.vendor},
                                    property: {detail: omit(currentProperty, ["offer"])}
                                } as unknown as IApplicationData;

                                gtmInquiryOnApplicationSuccess(application, data, ViewType.MY_FAVOURITES_LIST, "standard");
                            }

                            await Promise.resolve();
                        }
                    });

                    //  After submit success
                    const userStorageDataToSave = pick(values, ["email", "name", "phone", "financing_services", "new_property_notification_consent"]);

                    setSentLeadFormValuesInStorage({
                        ...userStorageDataToSave,
                        phone: getPhoneString(userStorageDataToSave.phone)
                    });

                    if (onSubmitSuccess) {
                        onSubmitSuccess(
                            applicationData,
                            applicationOffers.sent,
                            applicationOffers.rejected,
                            applicationProperties.sent,
                            applicationProperties.rejected
                        );
                    }
                } catch (e: unknown) {
                    //  After submit failure
                    console.log("Error", e);
                }

                //  After success or failure
                if (onSubmitFinish) {
                    onSubmitFinish();
                }

                setSubmitting(false);
            }
        })();
    }, [isSubmitting]);

    return null;
};

export const FavouritesApplicationFormHandler = connect<IStateProps, IActionsProps, IFavouritesApplicationFormHandlerProps, IRPStore>(
    () => ({}),
    mapDispatchToProps
)(FavouritesApplicationFormHandlerC);

function mapDispatchToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                postFavouritesApplication,
                addAppliedOffers,
                fetchUserProfile,
                forceFetchUserProfile,
                gtmInquiryOnApplicationSuccess,
                authUserAfterApplicationSent,
                patchUserProfileFromApplication
            },
            dispatch
        )
    };
}

//  Utils
//Because of user creation and cookies each application should be send separately
async function asyncForEach(array: IFavouriteData[], callback: (element: IFavouriteData, index: number, array: IFavouriteData[]) => void) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

/*
    Below functions provide filtered data for api requests and return information about filtering
*/
interface IApplicationItems<T> {
    data: IFavouriteData[];
    sent: T[];
    rejected: T[];
}

interface IFavouriteData {
    id: number;
    type: string;
}

const getApplicationProperties = (selectedProperties: IPropertyDetail[]) =>
    selectedProperties.reduce<IApplicationItems<IPropertyDetail>>(
        (acc, property) => {
            if (isOfferRejected(property.offer)) {
                return {
                    ...acc,
                    rejected: [...acc.rejected, property]
                };
            }

            return {
                ...acc,
                sent: [...acc.sent, property],
                data: [...acc.data, {type: "property", id: property.id}]
            };
        },
        {sent: [], rejected: [], data: []}
    );

const getApplicationOffers = (selectedOffers: IOfferDetail[]) =>
    selectedOffers.reduce<IApplicationItems<IOfferDetail>>(
        (acc, offer) => {
            if (isOfferRejected(offer)) {
                return {
                    ...acc,
                    rejected: [...acc.rejected, offer]
                };
            }

            return {
                ...acc,
                sent: [...acc.sent, offer],
                data: [...acc.data, {type: "offer", id: offer.id}]
            };
        },
        {sent: [], rejected: [], data: []}
    );

const isOfferRejected = (offer: IPropertyDetailOffer | IOfferDetail) =>
    isVendorExcluded(offer.vendor.id) || !offer.is_active || offer.configuration.display_type !== OfferDisplayType.FOR_SALE;
