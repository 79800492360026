import {FormFieldType} from "@pg-mono/form";

import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";

export const getInitialFavouritesListApplicationFormValues = () => {
    const initialValues: IFavouritesListApplicationFormValues = {
        name: "",
        email: "",
        phone: {
            code: 48,
            number: ""
        },
        new_property_notification_consent: false,
        financing_services: false
    };

    return initialValues;
};

export const favouritesListApplicationFormFields = {
    name: FormFieldType.Input,
    email: FormFieldType.Input,
    phone: FormFieldType.PhoneField,
    financing_services: FormFieldType.Checkbox,
    new_property_notification_consent: FormFieldType.Checkbox
};
import {FormFieldType} from "@pg-mono/form";

import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";

export const getInitialFavouritesListApplicationFormValues = () => {
    const initialValues: IFavouritesListApplicationFormValues = {
        name: "",
        email: "",
        phone: {
            code: 48,
            number: ""
        },
        new_property_notification_consent: false,
        financing_services: false
    };

    return initialValues;
};

export const favouritesListApplicationFormFields = {
    name: FormFieldType.Input,
    email: FormFieldType.Input,
    phone: FormFieldType.PhoneField,
    financing_services: FormFieldType.Checkbox,
    new_property_notification_consent: FormFieldType.Checkbox
};
