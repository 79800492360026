import * as React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {m, p} from "@pg-design/helpers-css";
import {CheckIcon, CloseIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {OfferDisplayType} from "../../../offer/helpers/OfferDisplayType";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {IOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {isVendorExcluded} from "../../utils/is_vendor_excluded";

interface IListItemProps {
    image: string;
    name: string;
    address: string;
    vendorId: number;
    isActive?: boolean;
    displayType: number;
    displayStatus: IOfferDisplayStatus;
    phoneNumber: string | null;
    offerType: number;
    error: boolean;
    type: "offer" | "property";
    roomsCount?: number;
    area?: number;
}

export const FavouritesConfirmationListItem = (props: IListItemProps) => {
    const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
    const theme = useTheme();

    return (
        <li css={listItem}>
            <Image css={offerImage} src={props.image} alt={props.name} width="155" height="87" />

            <div css={contentWrap}>
                <div css={infoWithIcon}>
                    <div css={mainInfoHolder}>
                        <Text variant="body_copy_1" css={[m(0, 0, 0.5, 0)]}>
                            {props.type === "offer"
                                ? props.name
                                : `${getOfferTypeNameCapital(props.offerType)}, ${props.roomsCount && props.roomsCount} ${
                                      props.roomsCount && roomsPluralize(props.roomsCount)
                                  }, ${props.area && areaFormat(props.area)}`}
                        </Text>

                        <Text variant="info_txt_2">{props.address}</Text>
                    </div>
                    <div css={iconWrap}>
                        {props.error ? (
                            <CloseIcon size="1.92" wrapperColor={theme.colors.warning} wrapperSize="2.4" fill={theme.colors.secondary} />
                        ) : (
                            <CheckIcon size="1.92" wrapperColor={theme.colors.success} wrapperSize="2.4" fill={theme.colors.secondary} />
                        )}
                    </div>
                </div>
                {props.error && (
                    <div css={badgeWrap}>
                        <Badge variant="label_warning">{getErrorText(props.vendorId, Boolean(props.isActive), props.displayType, props.displayStatus)}</Badge>
                    </div>
                )}
            </div>
        </li>
    );
};

/*
    Utils
 */

function getErrorText(vendorId: number, isActive: boolean, displayType: OfferDisplayType, displayStatus: IOfferDisplayStatus) {
    if (isVendorExcluded(vendorId)) {
        return "Z tym deweloperem możesz skontaktować się jedynie telefonicznie";
    }

    if (!isActive || displayStatus === "limited") {
        return "Ta oferta nie jest aktualna";
    }

    if (displayType === OfferDisplayType.SOLD) {
        return "Ta oferta została już sprzedana";
    }

    return "Ta oferta nie jest dostępna";
}

/*
    Styles
 */

const listItem = (theme: Theme) => css`
    display: flex;
    background-color: ${theme.colors.gray[100]};
`;

const offerImage = css`
    width: 7.6rem;
    height: 6.4rem;
`;

const contentWrap = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

const infoWithIcon = css`
    display: flex;
    flex: 1 1 auto;
`;

const mainInfoHolder = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${p(1)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(1.5)}
    }
`;

const iconWrap = css`
    ${p(0, 3, 0, 2)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const badgeWrap = (theme: Theme) => css`
    ${p(0.5, 1, 2, 1)};
    display: flex;
    justify-content: flex-start;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(0.5, 2, 2, 2)};
    }
`;
import * as React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {m, p} from "@pg-design/helpers-css";
import {CheckIcon, CloseIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {OfferDisplayType} from "../../../offer/helpers/OfferDisplayType";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {IOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {isVendorExcluded} from "../../utils/is_vendor_excluded";

interface IListItemProps {
    image: string;
    name: string;
    address: string;
    vendorId: number;
    isActive?: boolean;
    displayType: number;
    displayStatus: IOfferDisplayStatus;
    phoneNumber: string | null;
    offerType: number;
    error: boolean;
    type: "offer" | "property";
    roomsCount?: number;
    area?: number;
}

export const FavouritesConfirmationListItem = (props: IListItemProps) => {
    const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
    const theme = useTheme();

    return (
        <li css={listItem}>
            <Image css={offerImage} src={props.image} alt={props.name} width="155" height="87" />

            <div css={contentWrap}>
                <div css={infoWithIcon}>
                    <div css={mainInfoHolder}>
                        <Text variant="body_copy_1" css={[m(0, 0, 0.5, 0)]}>
                            {props.type === "offer"
                                ? props.name
                                : `${getOfferTypeNameCapital(props.offerType)}, ${props.roomsCount && props.roomsCount} ${
                                      props.roomsCount && roomsPluralize(props.roomsCount)
                                  }, ${props.area && areaFormat(props.area)}`}
                        </Text>

                        <Text variant="info_txt_2">{props.address}</Text>
                    </div>
                    <div css={iconWrap}>
                        {props.error ? (
                            <CloseIcon size="1.92" wrapperColor={theme.colors.warning} wrapperSize="2.4" fill={theme.colors.secondary} />
                        ) : (
                            <CheckIcon size="1.92" wrapperColor={theme.colors.success} wrapperSize="2.4" fill={theme.colors.secondary} />
                        )}
                    </div>
                </div>
                {props.error && (
                    <div css={badgeWrap}>
                        <Badge variant="label_warning">{getErrorText(props.vendorId, Boolean(props.isActive), props.displayType, props.displayStatus)}</Badge>
                    </div>
                )}
            </div>
        </li>
    );
};

/*
    Utils
 */

function getErrorText(vendorId: number, isActive: boolean, displayType: OfferDisplayType, displayStatus: IOfferDisplayStatus) {
    if (isVendorExcluded(vendorId)) {
        return "Z tym deweloperem możesz skontaktować się jedynie telefonicznie";
    }

    if (!isActive || displayStatus === "limited") {
        return "Ta oferta nie jest aktualna";
    }

    if (displayType === OfferDisplayType.SOLD) {
        return "Ta oferta została już sprzedana";
    }

    return "Ta oferta nie jest dostępna";
}

/*
    Styles
 */

const listItem = (theme: Theme) => css`
    display: flex;
    background-color: ${theme.colors.gray[100]};
`;

const offerImage = css`
    width: 7.6rem;
    height: 6.4rem;
`;

const contentWrap = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

const infoWithIcon = css`
    display: flex;
    flex: 1 1 auto;
`;

const mainInfoHolder = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${p(1)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(1.5)}
    }
`;

const iconWrap = css`
    ${p(0, 3, 0, 2)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const badgeWrap = (theme: Theme) => css`
    ${p(0.5, 1, 2, 1)};
    display: flex;
    justify-content: flex-start;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(0.5, 2, 2, 2)};
    }
`;
