import React from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import {useFormikContext} from "formik";

import {m, mb, mt} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {FormikForm} from "@pg-mono/formik-utils";
import {RequestState} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {FinancingServicesField} from "../../../application/components/application_form/fields/FinancingServicesField";
import {NotificationConsentField} from "../../../application/components/application_form/fields/NotificationConsentField";
import {EmailVerificationPopover} from "../../../application/components/EmailVerificationPopover";
import {useEmailVerification} from "../../../application/hooks/use_email_verification";
import {FinancingServicesCopyTypes} from "../../../lead/types/FinancingServicesCopyTypes";
import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";

export const FavouritesApplicationForm = () => {
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const variables = useSelector((state: IRPStore) => state.variables.data ?? null);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    const {isSubmitting, isValid, handleBlur, submitForm, values} = useFormikContext<IFavouritesListApplicationFormValues>();
    const {list: favouriteProperties, fetchRequest: favouritePropertiesRequest} = useSelector((store: IRPStore) => store.myOffers.favourites.properties);
    const {list: favouriteOffers, fetchRequest: favouriteOffersRequest} = useSelector((store: IRPStore) => store.myOffers.favourites.offers);

    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();
    const rodoTermsUrl = rpAppLink.rodoTerms.base();
    const termsOfService = rpAppLink.termsOfService.base();

    const submitDisabled =
        favouriteProperties.length === 0 &&
        favouriteOffers.length === 0 &&
        favouritePropertiesRequest === RequestState.Success &&
        favouriteOffersRequest === RequestState.Success;

    const onSubmit = async () => {
        if (values?.email && !(await validateEmailOnce(values?.email))) {
            return;
        }

        return submitForm();
    };

    if (!variables) {
        return <Loader />;
    }

    return (
        <div>
            <div css={formStyle}>
                <FormikForm.Input css={fieldStyle} name="name" placeholder="Imię i nazwisko" onBlur={handleBlur} />
                <EmailVerificationPopover {...emailNotificationProps} popoverPlace="left" zIndex={10000}>
                    <FormikForm.Input css={fieldStyle} name="email" placeholder="Email" onBlur={handleBlur} />
                </EmailVerificationPopover>
                <FormikForm.PhoneField css={fieldStyle} name="phone" codes={variables.country_codes} placeholder="Numer telefonu" className="bt" />
                {userProfileData && (userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent) ? null : (
                    <NotificationConsentField css={fieldStyle} name="new_property_notification_consent" />
                )}
                <FinancingServicesField
                    css={fieldStyle}
                    name="financing_services"
                    copyType={FinancingServicesCopyTypes.MULTI_PROPERTY}
                    popoverPlace={isMobile ? "top" : "left"}
                />
                <FormikForm.Button css={mt(2)} type="button" disabled={!isValid || isSubmitting || submitDisabled} variant="filled_primary" onClick={onSubmit}>
                    Wyślij
                </FormikForm.Button>
            </div>
            <Text align="center" css={[mb(4), mt(1.5)]} variant="info_txt_1">
                Akceptuję{" "}
                <Link size="small" underline href={termsOfService} target="_blank">
                    regulamin serwisu
                </Link>
            </Text>

            <Text align="center" color={theme.colors.gray[600]} variant="info_txt_2">
                Administratorami Twoich danych osobowych są: Property Group sp. z o.o. i deweloper, którego dotyczy zapytanie.
                <br />
                <Link css={rodoLinkStyle} size="x-small" underline href={rodoTermsUrl} target="_blank">
                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                </Link>
            </Text>
        </div>
    );
};

/*
    Styles
 */
const formStyle = css`
    display: flex;
    flex-direction: column;
`;

const fieldStyle = css`
    ${m(0, 0, 2, 0)}
`;

const rodoLinkStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};
`;
import React from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import {useFormikContext} from "formik";

import {m, mb, mt} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {FormikForm} from "@pg-mono/formik-utils";
import {RequestState} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {FinancingServicesField} from "../../../application/components/application_form/fields/FinancingServicesField";
import {NotificationConsentField} from "../../../application/components/application_form/fields/NotificationConsentField";
import {EmailVerificationPopover} from "../../../application/components/EmailVerificationPopover";
import {useEmailVerification} from "../../../application/hooks/use_email_verification";
import {FinancingServicesCopyTypes} from "../../../lead/types/FinancingServicesCopyTypes";
import {IFavouritesListApplicationFormValues} from "./IFavouritesListApplicationFormValues";

export const FavouritesApplicationForm = () => {
    const theme = useTheme();
    const {isMobile} = useUserDevice();

    const variables = useSelector((state: IRPStore) => state.variables.data ?? null);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    const {isSubmitting, isValid, handleBlur, submitForm, values} = useFormikContext<IFavouritesListApplicationFormValues>();
    const {list: favouriteProperties, fetchRequest: favouritePropertiesRequest} = useSelector((store: IRPStore) => store.myOffers.favourites.properties);
    const {list: favouriteOffers, fetchRequest: favouriteOffersRequest} = useSelector((store: IRPStore) => store.myOffers.favourites.offers);

    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();
    const rodoTermsUrl = rpAppLink.rodoTerms.base();
    const termsOfService = rpAppLink.termsOfService.base();

    const submitDisabled =
        favouriteProperties.length === 0 &&
        favouriteOffers.length === 0 &&
        favouritePropertiesRequest === RequestState.Success &&
        favouriteOffersRequest === RequestState.Success;

    const onSubmit = async () => {
        if (values?.email && !(await validateEmailOnce(values?.email))) {
            return;
        }

        return submitForm();
    };

    if (!variables) {
        return <Loader />;
    }

    return (
        <div>
            <div css={formStyle}>
                <FormikForm.Input css={fieldStyle} name="name" placeholder="Imię i nazwisko" onBlur={handleBlur} />
                <EmailVerificationPopover {...emailNotificationProps} popoverPlace="left" zIndex={10000}>
                    <FormikForm.Input css={fieldStyle} name="email" placeholder="Email" onBlur={handleBlur} />
                </EmailVerificationPopover>
                <FormikForm.PhoneField css={fieldStyle} name="phone" codes={variables.country_codes} placeholder="Numer telefonu" className="bt" />
                {userProfileData && (userProfileData.email_marketing_consent || userProfileData.phone_marketing_consent) ? null : (
                    <NotificationConsentField css={fieldStyle} name="new_property_notification_consent" />
                )}
                <FinancingServicesField
                    css={fieldStyle}
                    name="financing_services"
                    copyType={FinancingServicesCopyTypes.MULTI_PROPERTY}
                    popoverPlace={isMobile ? "top" : "left"}
                />
                <FormikForm.Button css={mt(2)} type="button" disabled={!isValid || isSubmitting || submitDisabled} variant="filled_primary" onClick={onSubmit}>
                    Wyślij
                </FormikForm.Button>
            </div>
            <Text align="center" css={[mb(4), mt(1.5)]} variant="info_txt_1">
                Akceptuję{" "}
                <Link size="small" underline href={termsOfService} target="_blank">
                    regulamin serwisu
                </Link>
            </Text>

            <Text align="center" color={theme.colors.gray[600]} variant="info_txt_2">
                Administratorami Twoich danych osobowych są: Property Group sp. z o.o. i deweloper, którego dotyczy zapytanie.
                <br />
                <Link css={rodoLinkStyle} size="x-small" underline href={rodoTermsUrl} target="_blank">
                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                </Link>
            </Text>
        </div>
    );
};

/*
    Styles
 */
const formStyle = css`
    display: flex;
    flex-direction: column;
`;

const fieldStyle = css`
    ${m(0, 0, 2, 0)}
`;

const rodoLinkStyle = (theme: Theme) => css`
    color: ${theme.colors.secondary};
`;
