import {Dispatch} from "redux";

import {catch400, catch403, postRequest, Response400} from "@pg-mono/request";
import {apiPath} from "@pg-mono/rp-api-routes";

import {applicationCreateTypes} from "../../application/actions/application_form_actions";
import {IApplication} from "../../application/types/IApplication";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {patchUserConsent} from "../../user/api/patch_user_consent";
import {MarketingConsentType} from "../../user/ts/enums/MarketingConsentType";

interface IPropertyConsultingOptions {
    on400Response?: (error: Response400) => void;
}

const applicationApiLink = apiPath.applications.create.base;

export const postFavouritesApplication =
    (jsonData: Partial<IApplication>, storeHash: string, options: IPropertyConsultingOptions = {}) =>
    (dispatch: Dispatch): Promise<IApplication | void> => {
        dispatch({type: applicationCreateTypes.start, id: storeHash});

        return postRequest({}, applicationApiLink, jsonData)
            .then((application: IApplication) => {
                dispatch({type: applicationCreateTypes.success, id: storeHash, result: application});

                if (application.user_created && application.new_property_notification_consent) {
                    patchUserConsent({[MarketingConsentType.PHONE]: true, [MarketingConsentType.EMAIL]: true});
                }
                return application;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: applicationCreateTypes.error, id: storeHash, error: error.appError});
                    options.on400Response && options.on400Response(error);
                })
            )
            .catch(
                catch403((error) => {
                    dispatch({type: applicationCreateTypes.error, id: storeHash, error: null});
                    notifyBugsnag(error, "postRecommendedProperties: catch 403", error.responseError ?? {});
                })
            );
    };
import {Dispatch} from "redux";

import {catch400, catch403, postRequest, Response400} from "@pg-mono/request";
import {apiPath} from "@pg-mono/rp-api-routes";

import {applicationCreateTypes} from "../../application/actions/application_form_actions";
import {IApplication} from "../../application/types/IApplication";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {patchUserConsent} from "../../user/api/patch_user_consent";
import {MarketingConsentType} from "../../user/ts/enums/MarketingConsentType";

interface IPropertyConsultingOptions {
    on400Response?: (error: Response400) => void;
}

const applicationApiLink = apiPath.applications.create.base;

export const postFavouritesApplication =
    (jsonData: Partial<IApplication>, storeHash: string, options: IPropertyConsultingOptions = {}) =>
    (dispatch: Dispatch): Promise<IApplication | void> => {
        dispatch({type: applicationCreateTypes.start, id: storeHash});

        return postRequest({}, applicationApiLink, jsonData)
            .then((application: IApplication) => {
                dispatch({type: applicationCreateTypes.success, id: storeHash, result: application});

                if (application.user_created && application.new_property_notification_consent) {
                    patchUserConsent({[MarketingConsentType.PHONE]: true, [MarketingConsentType.EMAIL]: true});
                }
                return application;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: applicationCreateTypes.error, id: storeHash, error: error.appError});
                    options.on400Response && options.on400Response(error);
                })
            )
            .catch(
                catch403((error) => {
                    dispatch({type: applicationCreateTypes.error, id: storeHash, error: null});
                    notifyBugsnag(error, "postRecommendedProperties: catch 403", error.responseError ?? {});
                })
            );
    };
